<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.main {
  flex-grow: 1;
  margin-top: 12px;
  height: 100%;
  background: white;
  border-radius: 8px;
}
</style>
<template>
  <div class="box">
    <!-- 过滤区域 -->
    <box-item>
      <div class="filter-container">
        <div class="filter-input-container">
          <!-- 区域 -->
          <div class="input-item">
            <div class="input-item-title">能流图</div>
            <el-select v-model="v1">
              <el-option :value="1" label="高压配电系统"></el-option>
              <el-option :value="2" label="一度制剂公司"></el-option>
            </el-select>
          </div>

          <!-- 报告期 -->

          <div class="input-item">
            <div class="input-item-title">报告期</div>
            <el-date-picker
              v-model="v2"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>

          <!-- <el-input></el-input> -->
        </div>

        <el-button type="primary" @click="loadData">提交</el-button>
      </div>
    </box-item>

    <div class="main">
      <chart-base :options="option"></chart-base>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const v1 = ref(1);
const v2 = ref(null);
const option = ref({});
const loadData = () => {
  if (v1.value == 1) {
  } else {
  }

  option.value = {
    series: {
      type: "sankey",
      layout: "none",
      emphasis: {
        focus: "adjacency",
      },
      data: [
        {
          name: "10KV进线",
          itemStyle: {
            color: "rgb(73, 64, 255)",
          },
        },
        {
          name: "公用电力",
          itemStyle: {
            color: "rgb(124, 255, 178)",
          },
        },
        {
          name: "工厂",
          itemStyle: {
            color: "rgb(141, 75, 227)",
          },
        },
        {
          name: "市政府",
          itemStyle: {
            color: "rgb(132,131,138)",
          },
        },
        {
          name: "办公楼",
          itemStyle: {
            color: "rgb(255,110,118)",
          },
        },
        {
          name: "商场",
          itemStyle: {
            color: "rgb(88,247,249)",
          },
        },
        {
          name: "酒店",
          itemStyle: {
            color: "rgb(5,192,145)",
          },
        },
        {
          name: "博物馆",
          itemStyle: {
            color: "rgb(255,138,69)",
          },
        },
        {
          name: "住宅小区",
          itemStyle: {
            color: "rgb(221,121,255)",
          },
        },
        {
          name: "生产区",
          itemStyle: {
            color: "rgb(73, 146, 255)",
          },
        },
        {
          name: "办公区",
          itemStyle: {
            color: "rgb(124,255,178)",
          },
        },
        {
          name: "实验区",
          itemStyle: {
            color: "rgb(253,122,96)",
          },
        },
      ],
      links: [
        {
          source: "10KV进线",
          target: "公用电力",
          value: 11,
        },
        {
          source: "公用电力",
          target: "工厂",
          value: 1,
        },
        {
          source: "公用电力",
          target: "市政府",
          value: 1,
        },
        {
          source: "公用电力",
          target: "办公楼",
          value: 2,
        },
        {
          source: "公用电力",
          target: "商场",
          value: 1,
        },
        {
          source: "公用电力",
          target: "酒店",
          value: 1,
        },
        {
          source: "公用电力",
          target: "博物馆",
          value: 1,
        },
        {
          source: "公用电力",
          target: "住宅小区",
          value: 2,
        },
        {
          source: "工厂",
          target: "生产区",
          value: 1,
        },
        {
          source: "工厂",
          target: "办公区",
          value: 1,
        },
        {
          source: "工厂",
          target: "实验区",
          value: 1,
        },
      ],
      lineStyle: {
        color: "source",
        curveness: 0.5,
      },
    },
  };
};
</script>
